<template>
    <div>
        <!-- {{ $t('home.home') }} -->

        <!-- <Announcement
        :announcement="announcements[0]"></Announcement> -->
    </div>
</template>

<script>
import Vue from 'vue'
// import Announcement from '@/components/Announcement'
import announcements from '@/components/announcements'
export default Vue.extend({
    name: "home",
    components: {
        // Announcement
    },
    data () {
        return {
            announcements
        }
    }
})
</script>